import React, { useState, useEffect } from 'react';
import './AnimalesDashboard.css';
import animalService from '../../../services/animal.service';
import userService from '../../../services/user.service';

export const AnimalesDashboard = ({ user, handleClose }) => {
    const [animalesUser, setAnimalesUser] = useState([]);

    useEffect(() => {
        const getAnimals = async () => {
            try {
                const response = await userService.getAnimalList(user._id); // Suponiendo que envías el ID del usuario
                
                setAnimalesUser(response.data); // Asegúrate de que la respuesta tenga los datos de los animales
                console.log(response);
            } catch (error) {
                console.error('Error al obtener animales:', error);
            }
        };

        getAnimals();
    }, []);

    return (
        <div className='backgroundRoleForm'>
            <h2>Animales de {user.name}</h2>

            <div className="animales-container">
                {animalesUser?.length > 0 ? (
                    animalesUser.map((animal) => (
                        <div className="animal-card" key={animal._id}>
                            <h3>{animal.name}</h3>
                            <p><strong>eventos:</strong> {animal.events.length}</p>
                            <p><strong>Edad:</strong> {animal.type} años</p>
                            <p><strong>Descripción:</strong> {animal.description}</p>
                        </div>
                    ))
                ) : (
                    <p>No se encontraron animales para este usuario.</p>
                )}

                <span className='Cerrar' onClick={handleClose}>X</span>
            </div>
        </div>
    );
};
