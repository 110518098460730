import React, { useEffect, useState } from 'react';
import './UserRoleDash.css';
import rolesService from '../../../services/roles.service';
import userService from '../../../services/user.service'; // Importa el servicio de usuario

export const UserRoleDash = ({ user, handleClose }) => {
    const [roles, setRoles] = useState([]); // Iniciar como array vacío
    const [selectedRole, setSelectedRole] = useState(''); // Estado para almacenar el rol seleccionado

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await rolesService.getRoles(); // Esperar la promesa
                setRoles(response.data); // Asignar la respuesta a 'roles'
                console.log(response.data);
            } catch (error) {
                console.error('Error al obtener los roles:', error);
            }
        };

        fetchRoles(); // Llamar a la función para obtener los roles
    }, []);

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value); // Actualizar el rol seleccionado
    };

    const handleAssignRole = async () => {
        if (!selectedRole) {
            alert('Por favor, selecciona un rol antes de asignarlo');
            return;
        }

        try {
            // Llama a la función postRoleToUser para asignar el rol
            const response = await userService.postRoleToUser(user._id, selectedRole);
            console.log(`Rol ${selectedRole} asignado al usuario ${user.name}`, response);
            alert(`Rol asignado correctamente a ${user.name}`);
        } catch (error) {
            console.error('Error al asignar rol:', error);
            alert('Hubo un error al asignar el rol.');
        }
    };

    return (
        <div className='backgroundRoleForm'>
            <div className="UserInfo">
                <h2>{user.name}</h2>
                <h3>Agregar rol</h3>

                {/* Mostrar los roles como opciones en un select */}
                <select value={selectedRole} onChange={handleRoleChange}>
                    <option value="" disabled>Selecciona un rol</option>
                    {roles.map((role) => (
                        <option key={role._id} value={role._id}>{role.name}</option>
                    ))}
                </select>

                <button onClick={handleAssignRole} className='btn btn-primary'>Asignar rol</button>
                <button onClick={handleClose} className='btn btn-danger'>Cerrar</button>
            </div>
        </div>
    );
};
