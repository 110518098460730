import React, { Component } from "react";
import { Routes, Route,Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";



import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";

import Nav  from "./components/nav.component";
import Animal from "./components/animal/animal.component";

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import { Profile } from "./components/ProfileComponent/Profile";
import { CrudAnimal } from "../src/components/animal/AnimalVisualizacion/CrudAnimal/CrudAnimal";
import {NavbarPetPass} from "../src/components/Navbar/NavbarPetPass"
import { NoLog } from "./components/noLog/NoLoged";
import { Footer } from "./components/animal/Footer/Footer";
import { Btnwhatsapp } from "./components/btnWhatsapp/Btnwhatsapp";
import { PublicContent } from "./components/PublicContent/PublicContent";
import { DashBoard } from "./components/DashBoard/DashBoard";
import { Recupero } from "./components/RecuperoPassword/Recupero";
import { PasswordChange } from "./components/RecuperoPassword/PasswordChange/PasswordChange";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  async componentDidMount() {
    const user = await  AuthService.getCurrentUser();
    console.log(user)
    if (user){
      this.setState({
        currentUser: user,
        //showModeratorBoard: user.Rolees.includes("RoleE_MODERATOR"),
        //showAdminBoard: user.Rolees.includes("RoleE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div>
        <div>
          <NavbarPetPass currentUser={currentUser}></NavbarPetPass>
          
          <div className="container mt-3">
            {currentUser ? (
              <Routes>
                <Route path="/profile" element={<Profile currentUser={currentUser} />} />
                <Route path="/animal" element={<Animal />} />
                <Route path="/animal/:id" element={<CrudAnimal></CrudAnimal>} />
                <Route path="/Blog" element={<PublicContent currentUser={currentUser}></PublicContent>} />
                <Route path="/DashBoard" element={<DashBoard currentUser={currentUser}></DashBoard>} />
                <Route path="*" element={<Animal></Animal>} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password/:token" element={<PasswordChange></PasswordChange>} />
                <Route path="/RecuperarContraseña" element={<Recupero></Recupero>}></Route>
                <Route path="*" element={<PublicContent></PublicContent>} />
              </Routes>
            )}
          </div>
          <Btnwhatsapp></Btnwhatsapp>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}


export default App;


