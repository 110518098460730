import React, { useState, useEffect } from 'react';
import './UserDashboard.css';
import userService from '../../../services/user.service';
import { AnimalesDashboard } from '../AnimalesDashboard/AnimalesDashboard';
import { UserRoleDash } from '../UserRoleDash/UserRoleDash';

export const UserDashboard = ({ users }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [noResults, setNoResults] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null); // Estado para el usuario seleccionado
    const [showAnimalesDashboard, setShowAnimalesDashboard] = useState(false); // Estado para controlar la visibilidad del dashboard de animales
    const [showUserRoleDash, setShowUserRoleDash] = useState(false); // Estado para controlar la visibilidad del dashboard de roles

    useEffect(() => {
        // Si searchTerm está vacío, mostrar todos los usuarios
        if (searchTerm === '') {
            setFilteredUsers(users);
            setNoResults(false);
        }
    }, [searchTerm, users]);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async (email) => {
        if (email === '') {
            setFilteredUsers(users);
            setNoResults(false);
            return;
        }

        try {
            const response = await userService.getUserByEmail(email);
            if (response.data) {
                setFilteredUsers([response.data]);
                setNoResults(false);
            } else {
                setFilteredUsers([]);
                setNoResults(true);
            }
            console.log("Usuario buscado", response.data);
        } catch (error) {
            console.error('Error al buscar usuario:', error);
            setFilteredUsers([]);
            setNoResults(true);
        }
    };

    const handleViewAnimales = (user) => {
        setSelectedUser(user); // Guardar el usuario seleccionado
        setShowAnimalesDashboard(true); // Mostrar el dashboard de animales
    };

    const handleNoViewAnimales = () => {
        setShowAnimalesDashboard(false);
    };


    const handleViewRoles = (user) => {
        setSelectedUser(user);
        setShowUserRoleDash(true);
    };

    const handleCloseRoles = () => {
        setShowUserRoleDash(false);
    };

    return (
        <div className='UserDashboard'>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Buscar usuarios..."
                    value={searchTerm}
                    onChange={handleChange}
                />
                <button
                    className="search-button"
                    onClick={() => handleSearch(searchTerm)}
                >
                    🔍
                </button>
            </div>

            <div className="UsersContainer">
                {filteredUsers.length === 0 && noResults ? (
                    <p>No se encontraron usuarios.</p>
                ) : (
                    filteredUsers.map((user) => (
                        <div className='user-card' key={user._id}>
                            <h3>{user.name}</h3>
                            <p><strong>Email:</strong> {user.email}</p>
                            <p><strong>Teléfono:</strong> {user.phone}</p>
                            {user.roles?.map((role) => (
                                <p key={role.id}><strong>Rol:</strong> {role.name}</p>
                            ))}

                            <button onClick={() => handleViewRoles(user)}>
                                Agregar rol
                            </button>
                            <button className='btnDashVerAnimales' onClick={() => handleViewAnimales(user)}>
                                Ver animales
                            </button>
                        </div>
                    ))
                )}
            </div>

            {showAnimalesDashboard && selectedUser && (
                <AnimalesDashboard user={selectedUser} handleClose={handleNoViewAnimales} />
            )}

            {/* Mostrar UserRoleDash solo si se seleccionó un usuario */}
            {showUserRoleDash && selectedUser && (
                <UserRoleDash user={selectedUser} handleClose={handleCloseRoles} />
            )}
        </div>
    );
};
