import React, { useEffect, useState } from 'react';
import "./RolesCrud.css";
import { RoleForm } from './RoleForm/RoleForm';
import rolesService from '../../../services/roles.service';
import Swal from 'sweetalert2';

export const RolesCrud = ({ roles, setCrudRoles }) => {
    const [rolForm, setRolForm] = useState(false);
    const [editingRole, setEditingRole] = useState(null);
    const [RolesList, setRoles] = useState([]);

    useEffect(() => { 
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const currentRoles = await rolesService.getRoles();
            setRoles(currentRoles.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleDelete = async (role) => {
        try {
            const response = await rolesService.deleteRole(role._id);
            console.log('Role deleted:', response);

            Swal.fire({
                position: "bottom",
                icon: "success",
                title: "Se eliminó correctamente",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: 'animate__animated animate__fadeInUp animate__faster'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutDown animate__faster'
                }
            });

            fetchRoles();
        } catch (error) {
            console.error('Error deleting role:', error);
        }
    };

    const handleEdit = (role) => {
        setEditingRole(role);
        setRolForm(true);
    };

    const handleRolForm = () => {
        setRolForm(!rolForm);
        setEditingRole(null);
    };

    return (
        <div className='RolesCrud'>
            <h2>Roles</h2>
            <button className='add-role-button' onClick={handleRolForm}>
                {rolForm ? "Cerrar Formulario" : "Agregar Rol"}
            </button>

            {rolForm ? (
                <RoleForm
                    roles={RolesList}
                    handleRolForm={handleRolForm}
                    roleToEdit={editingRole}
                    onRoleUpdated={fetchRoles}
                />
            ) : (
                <ul>
                    {RolesList && RolesList.length > 0 ? (
                        RolesList.map((role, index) => (
                            <li key={index} className='role-item'>
                                <div className="item">
                                    <h3>{role.name}</h3>
                                    <a>permissions: {role.permissions.join(', ')}</a>
                                    <a>
                                        Allow Routes: {role.allowed_routes && role.allowed_routes.length > 0 ? (
                                            <ul>
                                                {role.allowed_routes.map((route, routeIndex) => (
                                                    <li key={routeIndex} className="route-item">{route}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span>No routes available</span>
                                        )}
                                    </a>
                                </div>
                                <div className="btnDeleteS">
                                    <button
                                        className='edit-role-button'
                                        onClick={() => handleEdit(role)}
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className='delete-role-button'
                                        onClick={() => handleDelete(role)}
                                    >
                                        Eliminar
                                    </button>
                                </div>
                            </li>
                        ))
                    ) : (
                        <p>No roles found.</p>
                    )}
                </ul>
            )}
        </div>
    );
};
