import axios from 'axios';
import {authHeader} from './auth-header';

const API_URL= process.env.REACT_APP_API_URL;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  postForgotPassword(email) {
    return axios.post(`${API_URL}auth/forgot-password`, { email });
}

postResetPassword(newPassword) {
  console.log(newPassword)
  return axios.post(`${API_URL}auth/reset-password`,  newPassword )
}

  getUserByEmail(email) {
    console.log(email)
    return axios.get(API_URL + 'admin/users/' + email, {headers: authHeader()})
}

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  patchProfileUser(values){
    return axios.patch(API_URL + 'users/me', values, { headers: authHeader() });

  }

  postRoleToUser(userId, roleId) {
    console.log(roleId)
    return axios.post(API_URL + 'admin/users/' + userId + '/roles/' + roleId, roleId,  { headers: authHeader()} )
  }

  getAnimalList(userId) {
    return axios.get(`${API_URL}admin/users/${userId}/animals`, { headers: authHeader() });
  }
  

}

 export default new UserService();
