import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './PasswordChange.css';
import userService from '../../../services/user.service';

export const PasswordChange = () => {
    const { token } = useParams(); 
    const navigate = useNavigate();

    // Estados para las contraseñas
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Si no hay token, redirige a la página de inicio
        if (!token) {
            navigate('/home');
        }

        console.log(token)
    }, [token, navigate]);

    // Función para manejar el cambio de contraseña
    const handlePasswordChange = async () => {
        if (newPassword === confirmPassword) {
          const changePasswordData = {
            token: token,
            password: newPassword,
          };
      
          try {
            await userService.postResetPassword(changePasswordData);
            console.log("Contraseña cambiada con éxito");
            navigate('/login'); // Redirige al usuario a la página de login o muestra un mensaje de éxito
          } catch (error) {
            console.error("Error al cambiar la contraseña:", error);
            setError("Hubo un problema al cambiar la contraseña. Por favor, inténtalo de nuevo.");
          }
        } else {
          setError("Las contraseñas no coinciden");
        }
      };
      

    return (
        <div className='Recupero'>
            <div className="RecuperarSector">
                <h1>Cambia tu contraseña</h1>
                
                <div className="emailSector">
                    <a href="#">Nueva contraseña</a>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>

                <div className="emailSector">
                    <a href="#">Repetir contraseña</a>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>

                {error && <p className="error-message">{error}</p>}

                <button
                    className='btn btn-primary btn-block'
                    onClick={handlePasswordChange}
                    disabled={!newPassword || !confirmPassword || newPassword !== confirmPassword}
                >
                    Cambiar
                </button>
            </div>
        </div>
    );
};
