import React, { useEffect, useState } from 'react';
import './DashBoard.css';
import rolesService from '../../services/roles.service';
import animalService from '../../services/animal.service';
import { RolesCrud } from './RolesCrud/RolesCrud';
import { UserDashboard } from './UserDashboard/UserDashboard';

export const DashBoard = () => {
    const [RolesList, setRoles] = useState(null);
    const [AnimalList, setAnimals] = useState(null);
    const [UsersList, setUsers] = useState(null);

    const [CrudRoles, setCrudRoles] = useState(false);
    const [userDashboard, setUserDashboard] = useState(false);

    const handleCrudRoles = () => {
        setCrudRoles(!CrudRoles);
        if (userDashboard) {
            setUserDashboard(false); // Oculta UserDashboard si se muestra CrudRoles
        }
    };

    const handleUserDashboard = () => {
        setUserDashboard(!userDashboard);
        if (CrudRoles) {
            setCrudRoles(false); // Oculta RolesCrud si se muestra UserDashboard
        }
    };

    useEffect(() => {
        const getRoles = async () => {
            const currentRoles = await rolesService.getRoles();
            console.log(currentRoles.data);
            setRoles(currentRoles.data);
        };

        const getAnimals = async () => {
            const currentAnimals = await animalService.getAnimals();
            console.log(currentAnimals);
            setAnimals(currentAnimals);
        };

        const getAllUsers = async () => {
            const getAllUsers = await rolesService.getAllUsers();
            console.log(getAllUsers.data);
            setUsers(getAllUsers.data);
        };

        getRoles();
        getAnimals();
        getAllUsers();
    }, []);

    return (
        <div className='DashBoard'>
            <div className="ContentTargetContainer">
                <h1>DashBoard</h1>

                <div className="TargetContainer">
                    <div className="RolesTarget Target" onClick={handleCrudRoles}>
                        <h3>Roles</h3>
                        <p>{RolesList?.length} Roles Listados</p>
                        <button>Ver mas</button>
                    </div>

                    <div className="UsersTarget Target" onClick={handleUserDashboard}>
                        <h3>Usuarios</h3>
                        <p>{UsersList?.length} Usuarios Listados</p>
                        <button>Ver mas</button>
                    </div>


                </div>

                {CrudRoles && <RolesCrud roles={RolesList} setCrudRoles={setCrudRoles} />}
                {userDashboard && <UserDashboard users={UsersList} />}
            </div>
        </div>
    );
};
