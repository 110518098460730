import React, { useState } from 'react';
import './Recupero.css';
import userService from '../../services/user.service';

export const Recupero = () => {
  const [email, setEmail] = useState(''); // Estado para almacenar el correo
  const [isEmailSent, setIsEmailSent] = useState(false); // Estado para indicar si el correo fue enviado
  const [error, setError] = useState(null); // Estado para almacenar errores

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Actualiza el estado con el valor del input
  };

  const handleRecoverPassword = async () => {
    try {
      await userService.postForgotPassword(email);
      setIsEmailSent(true); // Actualiza el estado para indicar que el correo se envió correctamente
    } catch (error) {
      setError("Hubo un error al enviar el correo."); // Muestra un mensaje de error
      console.error("Error:", error);
    }
  };

  return (
    <div className='Recupero'>
      {isEmailSent ? (
        <div>Se envió el email a {email}.</div>
      ) : (
        <div className="RecuperarSector">
          <h1>Recuperar contraseña</h1>
          <div className="emailSector">
            <a href="#">Ingresa tu mail</a>
            <input
              type="text"
              value={email} // Asigna el valor del estado al input
              onChange={handleEmailChange} // Asigna el manejador de cambios al input
            />
          </div>
          <button
            className='btn btn-primary btn-block'
            onClick={handleRecoverPassword} // Asigna el manejador de clic al botón
          >
            Recuperar contraseña
          </button>
          {error && <div className="error">{error}</div>} {/* Muestra el error si existe */}
        </div>
      )}
    </div>
  );
};
